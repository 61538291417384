import React, { useState, useEffect } from 'react'
// import { useDispatch } from 'react-redux'
import { Label, Input, Select, Textarea, Radio, Checkbox } from '@rebass/forms'
import { Box, Button, Flex, Card, Text } from 'rebass'
import { useTheme } from '@emotion/react'
import { connect } from 'react-redux'
import { createContact } from '../actions/contact'

// export const Contact = (props) => {
//   return (
//     <div className="container">
//       <div>
//         <span>Send Us A Message:</span>
//       </div>

//       <form onSubmit={props.handleSubmit}>
//         <section>
//           <fieldset>
//             <div>
//               <input
//                 className="nameBox"
//                 name="firstName"
//                 type="text"
//                 value={props.firstName}
//                 onChange={props.handleInput}
//                 placeholder="First Name"
//               />
//             </div>
//             <div>
//               <input
//                 className="nameBox"
//                 name="lastName"
//                 type="text"
//                 value={props.lastName}
//                 onChange={props.handleInput}
//                 placeholder="Last Name"
//               />
//             </div>
//             <div>
//               <input
//                 className="companyBox"
//                 name="companyName"
//                 type="text"
//                 value={props.companyName}
//                 onChange={props.handleInput}
//                 placeholder="Company Name"
//               />
//             </div>
//           </fieldset>
//           <fieldset>
//             <div>
//               <input
//                 className="emailBox"
//                 name="emailAddress"
//                 type="text"
//                 value={props.emailAddress}
//                 onChange={props.handleInput}
//                 placeholder="Email"
//               />
//             </div>
//             <div>
//               <input
//                 className="stateAndInquiry"
//                 name="inquiryType"
//                 type="text"
//                 value={props.inquiryType}
//                 onChange={props.handleInput}
//                 placeholder="Inquiry Type"
//               />
//             </div>
//             <div>
//               <select
//                 className="stateAndInquiry"
//                 placeholder="Location"
//                 name="location"
//                 type="dropdown">
//                 <option defaultValue="" id="locationOption">
//                   Location
//                 </option>
//                 {props.states.map((state, index) => {
//                   return (
//                     <option key={index} value={state}>
//                       {state}
//                     </option>
//                   )
//                 })}
//               </select>
//             </div>
//             <div>
//               <input
//                 className="phoneBox"
//                 name="phoneNumber"
//                 type="text"
//                 value={props.phoneNumber}
//                 onChange={props.handleInput}
//                 placeholder="Phone"
//               />
//             </div>
//           </fieldset>
//           <div>
//             <textarea
//               className="messageBox"
//               name="message"
//               type="text"
//               value={props.message}
//               onChange={props.handleInput}
//               placeholder="Message"
//             />
//           </div>
//           <input type="submit" value="Submit" />
//         </section>
//       </form>
//     </div>
//   )
// }

export const Contact = (props) => {
  const theme = useTheme()
  const [contact, setContact] = useState(null)
  const [formError, setFormError] = useState(null)
  // const dispatch = useDispatch()
  useEffect(() => {
    console.log('form error after', formError)
  })
  const handleInput = (e) => {
    setContact({ ...contact, [e.target.name]: e.target.value })
    console.log('CONTACT', contact)
  }

  const validateForm = () => {
    const isValidEmail = validateEmail(contact.email)
    let errorObj = {}
    if (!isValidEmail) {
      console.log('IS VALID EMAIL', isValidEmail)
      console.log('FORM ERROR email', formError)

      errorObj = { ...errorObj, email: 'invalid email address' }
    }

    if (!contact.message) {
      console.log('FORM ERROR message', formError)
      errorObj = { ...errorObj, message: 'message is required' }
    }

    if (!contact.name) {
      console.log('FORM ERROR name', formError)
      errorObj = { ...errorObj, name: 'name is required' }
    }

    if (!contact.subject) {
      console.log('FORM ERROR subject', formError)
      errorObj = { ...errorObj, subject: 'subject is required' }
    }
    if (Object.keys(errorObj).length) {
      console.log('SET ERRORS', errorObj)
      setFormError(errorObj)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    await validateForm()

    if (formError || !contact || Object.keys(contact).length < 4) {
      return
    }

    console.log('FORM ERROR in Handle submit', formError)
    const contactObj = {
      name: contact.name,
      subject: contact.subject,
      emailAddress: contact.emailAddress,
      message: contact.message,
    }
    console.log('CONTACT OBJ', { ...contactObj })

    createContact(contactObj)

    setContact({
      name: '',
      subject: '',
      emailAddress: '',
      message: '',
    })
  }

  console.log('FORM ERROR', formError)
  return (
    <div
      style={{
        justifyContent: 'center',
        // width: '100%',
        display: 'flex',
      }}>
      <Card className="contactForm">
        <Text className="contactUs">Send us a message:</Text>
        <Box
          as="form"
          data-netlify-recaptcha="true"
          // onSubmit={(e) => e.preventDefault()}
          py={10}>
          <Flex mx={5} mb={3}>
            <Box width={['50%', '100%']} px={2}>
              <Label className="label" htmlFor="name">
                Name
                {formError?.email && (
                  <span style={{ color: 'red', paddingLeft: '.5em' }}>
                    {formError.name}
                  </span>
                )}
              </Label>
              <Input
                id="name"
                name="name"
                value={contact?.name || ''}
                type="text"
                onChange={handleInput}
                className="contact-input"
              />
            </Box>
          </Flex>
          <Flex mx={5} mb={3}>
            <Box width={1} px={2}>
              <Label className="label" htmlFor="email">
                Email
                {formError?.email && (
                  <span style={{ color: 'red', paddingLeft: '.5em' }}>
                    {formError.email}
                  </span>
                )}
              </Label>

              <Input
                id="email"
                name="email"
                value={contact?.email || ''}
                className="contact-input"
                type="text"
                onChange={handleInput}
              />
            </Box>
          </Flex>
          <Flex mx={5} mb={3}>
            <Box width={1} px={2}>
              <Label className="label" htmlFor="subject">
                Subject
                {formError?.email && (
                  <span style={{ color: 'red', paddingLeft: '.5em' }}>
                    {formError.subject}
                  </span>
                )}
              </Label>
              <Input
                id="subject"
                autofill="off"
                name="subject"
                value={contact?.subject || ''}
                className="contact-input"
                type="text"
                onChange={handleInput}
                rows={1}
              />
            </Box>
          </Flex>
          <Flex mx={5} flexWrap="wrap">
            <Box width={1} px={2}>
              <Label className="label" htmlFor="message">
                Message
                {formError?.email && (
                  <span style={{ color: 'red', paddingLeft: '.5em' }}>
                    {formError.message}
                  </span>
                )}
              </Label>
              <Textarea
                className="contact-input"
                id="message"
                name="message"
                defaultValue=""
                rows={5}
                onChange={handleInput}
              />
            </Box>
          </Flex>
          <div data-netlify-recaptcha="true" />
          <Flex width={'10%'} py={4} flexWrap="nowrap">
            <Box mx={7}>
              <Button
                className="submitButton"
                onClick={handleSubmit}
                disabled={!contact}
                style={{
                  backgroundColor: theme.colors.primary,
                }}>
                Submit
              </Button>
            </Box>
          </Flex>
        </Box>
      </Card>
    </div>
  )
}

export default Contact

const validateEmail = (email) => {
  console.log('EMAOIL', email)
  if (email.length > 254) {
    return false
  }
  const regex =
    /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/

  const valid = regex.test(email)

  if (!valid) {
    console.log('FLADF')
    return false
  }

  // Further checking of some things regex can't handle
  const parts = email.split('@')

  if (parts[0].length > 64) {
    return false
  }

  const domainParts = parts[1].split('.')

  if (
    domainParts.some(function (part) {
      return part.length > 63
    })
  ) {
    console.log('RETURNING FALSAE')
    return false
  }

  return true
}
