import React from 'react'

export const Shop = () => {
  return (
    <div className="title">
      <h1>Shop</h1>
    </div>
  )
}
export default Shop
