import photos from '../media/photos/index'

export const hopTypes = {
  cascade: {
    image: photos.hops.cascade,
    name: 'Cascade',
    description: 'Fresh citrus and floral aroma',
    hopContent: {
      alpha: '6.0%',
      beta: '5.4%',
      oils: '2.3 mL/100g',
    },
  },
  chinook: {
    image: photos.hops.chinook,
    name: 'Chinook',
    description: 'Fresh spicy and fruity, with a slight piney aroma',
    hopContent: {
      alpha: '10.8%',
      beta: '2.6%',
      oils: '2.3 mL/100g',
    },
  },
  //   centennial: {
  //     image: photos.cascade,
  //     description: 'Fresh spicy and fruity, with a slight piney aroma',
  //     hopContent: {
  //       alpha: '10.8',
  //       beta: '2.6',
  //       oils: '2.3',
  //     },
  //   },
}
