import React from 'react'
import { Text, Card, Image } from 'rebass'
import { withTheme } from '@emotion/react'

import { hopTypes } from '../util/hops'

export const Hops = () => {
  const hopVarieties = Object.keys(hopTypes)
  return (
    <div style={{ justifyContent: 'center' }}>
      <div className="title">
        <h1 fontSize={30}>Our Varieties</h1>
      </div>
      <div className="hopContainer">
        {hopVarieties.map((variety) => {
          return <HopCard {...hopTypes[variety]} key={variety} />
        })}
      </div>
    </div>
  )
}

export default withTheme(Hops)

export const HopCard = (props) => {
  return (
    <Card className="hopCard" style={{ margin: '1em' }}>
      <div>
        <Image src={props.image} className="photoMedium" />
        <div>
          <Text className="hopCardTitle">{props.name}</Text>
          <div>
            <Text className="title">Description:</Text>
            <Text className="hopCardContent">{props.description}</Text>
          </div>
          <div>
            <Text className="title">Alpha Acids</Text>
            <Text className="hopCardContent">{props.hopContent.alpha}</Text>
          </div>

          <div>
            <Text className="title">Beta Acids</Text>
            <Text className="hopCardContent">{props.hopContent.beta}</Text>
          </div>
          <div>
            <Text className="title">Total Oils</Text>
            <Text className="hopCardContent">{props.hopContent.oils}</Text>
          </div>
          <Text>{props.variety}</Text>
        </div>
      </div>
    </Card>
  )
}
