import React from 'react'
import Navbar from './Navbar.jsx'
import img from '../media/hop_island_logo.png'
import { NavLink } from 'react-router-dom'

export const Root = () => {
  return <Navbar />
}

export default Root
