import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createContact } from '../actions/contact'
import { states } from '../util/states'
import Contact from '../components/Contact.jsx'

class ContactContainer extends Component {
  constructor() {
    super()
    this.state = {
      name: '',

      emailAddress: '',

      subject: '',
      message: '',
    }
    this.handleInput = this.handleInput.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(e) {
    e.preventDefault()

    const contactObj = {
      name: this.state.name,
      subject: this.state.subject,
      companyName: this.state.companyName,
      emailAddress: this.state.emailAddress,
      message: this.state.message,
    }

    this.props.createContact(contactObj)

    this.setState({
      name: '',
      emailAddress: '',
      subject: '',
      message: '',
    })
  }

  handleInput(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {
    return (
      <div>
        <Contact
          name={this.state.name}
          subject={this.state.subject}
          companyName={this.state.companyName}
          emailAddress={this.state.emailAddress}
          message={this.state.message}
          states={states}
          handleInput={this.handleInput}
          handleSubmit={this.handleSubmit}
          createContact={this.props.createContact}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    thankYouMessage: state.contactReducer.thankYouMessage,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createContact: (contactObj) => {
      dispatch(createContact(contactObj))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactContainer)
