import React from 'react'
import { NavLink } from 'react-router-dom'
import logo from '../media/hop_island_logo.png'

export const Navbar = () => {
  return (
    <React.Fragment>
      <nav className="navbarContainer" id="myNavbar">
        <div className="navbar-header">
          <NavLink to="/home">
            <img className="logoImage" src={logo} />
          </NavLink>
        </div>
        <ul className="nav">
          <li>
            <NavLink className="navBarLink" to="/home">
              Farm
            </NavLink>
          </li>
          <li>
            <NavLink className="navBarLink" to="/hops">
              Hops
            </NavLink>
          </li>

          <li>
            <NavLink className="navBarLink" to="/contact">
              Contact
            </NavLink>
          </li>
        </ul>
      </nav>
    </React.Fragment>
  )
}

export default Navbar
