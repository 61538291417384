import React from 'react'
import photos from '../media/photos'
import { Image } from 'rebass'
export const Home = () => {
  return (
    <div className="mainContainer">
      <div>
        <div className="photoContainer">
          <Image className="photo" src={photos.winterYard} />
        </div>
        <div className="title">
          <h1>About Hop Island Farms</h1>
        </div>
        <div className="aboutUsContent">
          <p>
            Located in Northwest Indiana and on some of the finest soil the
            earth has to offer. Hop Island was established to provide
            exceptional organically grown hops to home brewers and local
            breweries alike.
          </p>
          <p>
            Hop Island began in 2014 admist the craft beer explosion as an idea
            proposed by our Nona and Pop over a couple beers. Realizing the
            opportunity, they quickly sourced the materials needed to build the
            trellis and hop yard. In May of 2015 work began on the hop yard and
            it was finished over a laborous week in November with the help of
            numerous friends and family. Planting began in Spring of 2016.
          </p>
          <div className="photoContainer">
            <div>
              <img className="photoMedium" src={photos.november2015} />
            </div>
            <div>
              <img className="photoMedium" src={photos.summerVines} />
            </div>
            <div>
              <img className="photoMedium" src={photos.sunnyYard} />
            </div>
            <div>
              <img className="photoMedium" src={photos.brentTennisonTractor} />
            </div>
            <div>
              <img className="photoMedium" src={photos.tractorKids} />
            </div>
            <div>
              <img className="photoMedium" src={photos.nonaPop} />
            </div>
            <div>
              <img className="photoMedium" src={photos.nonaWithBabes} />
            </div>
            <div>
              <img className="photoMedium" src={photos.hopsOnVine} />
            </div>
            <div>
              <img className="photoMedium" src={photos.hopDrawer} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
