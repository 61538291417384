export const baseTheme = {
  fontSizes: [12, 14, 16, 24, 32, 48, 64],
  fonts: {
    body: 'Droid Serif serif',
    heading: 'Josefin Sans, sans-serif',
    monospace: 'Menlo, monospace',
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  colors: {
    primary: '#228B22',
    tertiary: '#568203',
    secondary: '#FBEC5D',
    gray: '#f6f6ff',
    charcoal: '#36454F',
    black: '#000000',
  },
  text: {
    primary: { color: '#568203' },
    body: 'secondary',
  },
  buttons: {
    primary: {
      color: 'white',
      bg: 'primary',
    },
    outline: {
      color: 'primary',
      bg: 'transparent',
      boxShadow: 'inset 0 0 0 2px',
    },
    secondary: {
      color: 'white',
      bg: 'secondary',
    },
  },
  breakpoints: [768, 1024],
}
