import hopDrawer from './hopdrawer.png'
import hopsOnVineInYard from './hopsOnVineInYard.png'
import hopsOnVine from './hopsOnVine.png'
import november2015 from './november2015.png'
import sunnyYard from './sunnyYard.png'
import winterYard from './winterFarm.png'
import summerVines from './summerVines.png'
import cascade from './hops/cascade.png'
import centennial from './hops/centennial.png'
import chinook from './hops/chinook.png'
import brentTennisonTractor from './brentTennisonTractor.png'
import nonaPop from './nonaPop.png'
import nonaWithBabes from './nonaWithBabes.png'
import tractorKids from './tractorKids.png'

export default {
  hopDrawer,
  hopsOnVine,
  hopsOnVineInYard,
  november2015,
  sunnyYard,
  winterYard,
  summerVines,
  nonaPop,
  nonaWithBabes,
  brentTennisonTractor,
  tractorKids,
  hops: {
    cascade,
    centennial,
    chinook,
  },
}
