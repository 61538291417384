import axios from 'axios'
import { SEND_THANK_YOU } from '../constants.js'

export const sendThankYou = () => {
  return {
    type: SEND_THANK_YOU,
    message: 'Thank You!',
  }
}

// export const createContact = (contactObj) => {
//   return (dispatch) => {
//     axios
//       .post('api/contact', {
//         firstName: contactObj.firstName,
//         lastName: contactObj.lastName,
//         companyName: contactObj.companyName,
//         emailAddress: contactObj.emailAddress,
//         inquiryType: contactObj.inquiryType,
//         location: contactObj.location,
//         phoneNumber: contactObj.phoneNumber,
//         message: contactObj.message,
//       })
//       .then(() => dispatch(sendThankYou()))
//       .catch((err) => console.log(err))
//   }
// }

export const createContact = async (contactObj) => {
  let response

  try {
    response = await axios.post('api/contact', {
      name: contactObj.name,
      subject: contactObj.subject,
      email: contactObj.emnail,
      message: contactObj.message,
    })

    if (response === 200) {
      sendThankYou()
    }
  } catch (e) {
    console.log('Error creating contact', e)
  }

  return response
  // .then(() => dispatch(sendThankYou()))
  // .catch((err) => console.log(err))
}
