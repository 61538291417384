import React from 'react'
import {
  Route,
  Routes as AppRoutes,
  HashRouter as Router,
} from 'react-router-dom'

import Home from './components/Home.jsx'
import ContactContainer from './containers/ContactContainer.jsx'
import Shop from './components/Shop.jsx'
import Buyers from './components/Buyers.jsx'
import Hops from './components/Hops.jsx'

import Root from './components/Index.jsx'

const Layout = (props) => {
  return <div className="layout">{props.children}</div>
}

const Routes = () => {
  return (
    <Router>
      <div>
        <Root />
        <AppRoutes>
          <Route
            path="/"
            element={
              <Layout>
                <Home />
              </Layout>
            }
          />
          <Route
            exact
            path="/home"
            element={
              <Layout>
                <Home />
              </Layout>
            }
          />
          <Route
            path="/about"
            element={
              <Layout>
                <Home />
              </Layout>
            }
          />
          <Route
            path="/hops"
            element={
              <Layout>
                <Hops />
              </Layout>
            }
          />
          <Route
            path="/farm"
            element={
              <Layout>
                <Home />
              </Layout>
            }
          />
          <Route
            path="/contact"
            element={
              <Layout>
                <ContactContainer />
              </Layout>
            }
          />
          <Route
            path="/buyers"
            element={
              <Layout>
                <Buyers />
              </Layout>
            }
          />
          <Route
            path="/shop"
            element={
              <Layout>
                <Shop />
              </Layout>
            }
          />
        </AppRoutes>
      </div>
    </Router>
  )
}

export default Routes
